<template>
  <v-tooltip top :color="color">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on" :to="link">
        <v-icon left>{{ icon }}</v-icon>
        {{ text }}
      </v-btn>
    </template>
    <span>{{ title }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    color: String,
    icon: String,
    text: String,
    title: String,
    link: {
      type: String,
      default: null,
    },
  },
};
</script>