<template>
  <v-card class="mx-auto" max-width="500">
    <v-list subheader>
      <v-subheader>Penghuni lain</v-subheader>

      <v-list-item v-for="(bill, index) in bills" :key="index">
        <v-list-item-avatar>
          <v-img alt="avatar" :src="img"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="bill.user.name"></v-list-item-title>
          <v-list-item-subtitle>{{ formatNumberRupiah(bill.total) }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon v-if="bill.paid_at" color="blue darken-3"> mdi-currency-usd </v-icon>
          <v-icon v-else color="grey"> mdi-currency-usd-off </v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
import { formatNumberRupiah } from '../helpers/helper'
export default {
  props: {
    bills: Array
  },
  data: () => ({
    img: 'https://cdn.vuetifyjs.com/images/lists/1.jpg'
  }),
  methods: {
    formatNumberRupiah
  }
}
</script>