<template>
  <div>
    <v-card color="light-blue darken-2" dark class="rounded-0">
      <div class="d-flex flex-no-wrap justify-space-between">
        <div>
          <v-card-title class="text-h5" v-text="invoice.name"></v-card-title>

          <v-card-subtitle v-text="formatNumberRupiah(invoice.total)"></v-card-subtitle>
          <v-card-text
            ><i>{{ invoice.description }}</i></v-card-text
          >

          <v-card-actions>
            <Tooltip icon="mdi-pencil" :text="formatDate(invoice.created_at)" title="Created" color="primary" />
            <DialogDeleteInvoice :id="id" :name="invoice.name" v-if="user && user.role == 'admin'" />
          </v-card-actions>
        </div>

        <v-avatar class="ma-5" tile>
          <v-icon x-large>mdi-receipt</v-icon>
        </v-avatar>
      </div>
    </v-card>
    <ImageGalery :images="invoice.images" />
    <list-user-invoice :bills="invoice.bills" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ImageGalery from '../components/ImageGalery.vue'
import ListUserInvoice from '../components/ListUserInvoice.vue'
import Tooltip from '../components/Tooltip.vue'
import DialogDeleteInvoice from '../components/dialog/DialogDeleteInvoice.vue'
import { formatNumberRupiah, formatDate } from '../helpers/helper'

export default {
  components: {
    ImageGalery,
    ListUserInvoice,
    Tooltip,
    DialogDeleteInvoice
  },
  data: () => ({
    name: 'Listrik Desember 2021',
    total: 'Rp 360.000'
  }),
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      invoice: (state) => state.invoice.invoice
    }),
    id() {
      return this.$route.params.id
    }
    // image() {
    //   return this.invoice.images != null && this.invoice.images.length > 0 ? this.invoice.images : []
    // }
  },
  methods: {
    ...mapActions('invoice', ['getInvoice']),
    formatNumberRupiah,
    formatDate
  },
  mounted() {
    this.getInvoice(this.id)
  }
}
</script>