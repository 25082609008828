<template>
  <v-row class="pa-3">
    <v-col class="d-flex child-flex" v-for="(item, idx) in imageAll" :key="idx">
      <v-img max-width="100%" :src="baseUrl + '/' + item.location"></v-img>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    images: Array
  },
  mounted() {},
  computed: {
    baseUrl() {
      return process.env.VUE_APP_API_URL
    },
    imageAll() {
      return this.images != null && this.images.length > 0 ? this.images : []
    }
  }
}
</script>