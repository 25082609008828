<template>
  <div>
    <div v-if="bill == null">Loading...</div>
    <div v-else>
      <v-card :color="'grey lighten-5'" dark class="rounded-0">
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title class="text-h5 black--text" v-text="bill.name"></v-card-title>

            <v-card-subtitle class="black--text" v-text="formatNumberRupiah(bill.total)"></v-card-subtitle>
            <v-card-text>
              <p v-if="bill.invoice && bill.invoice.description" class="black--text">
                {{ bill.invoice.description }}
              </p>
              <p class="black--text">{{ bill.description }}</p>
            </v-card-text>

            <v-card-actions>
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" class="black--text">
                    <v-icon left>mdi-pencil</v-icon>
                    {{ formatDate(bill.created_at) }}
                  </v-btn>
                </template>
                <span>Created</span>
              </v-tooltip>
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" class="black--text">
                    <template v-if="bill.paid_at">
                      <v-icon left>mdi-check</v-icon>
                      {{ formatDate(bill.paid_at) }}
                    </template>
                    <template v-else>
                      <v-icon left>mdi-close</v-icon>
                      Belum Dibayar
                    </template>
                  </v-btn>
                </template>
                <span v-if="bill.paid_at">Sudah Dibayar</span>
                <span v-else>Belum Dibayar</span>
              </v-tooltip>
            </v-card-actions>
          </div>

          <v-avatar class="ma-5" tile>
            <v-icon x-large v-if="bill.paid_at" color="green">mdi-clipboard-check</v-icon>
            <v-icon x-large v-else color="red">mdi-alert-box</v-icon>
          </v-avatar>
        </div>
      </v-card>
      <image-galery :images="bill.images" />
      <list-user-invoice v-if="bill.invoice" :bills="bill.invoice.bills" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ImageGalery from '../components/ImageGalery.vue'
import ListUserInvoice from '../components/ListUserInvoice.vue'
import { formatDate, formatNumberRupiah } from '../helpers/helper'
export default {
  components: {
    ImageGalery,
    ListUserInvoice
  },
  data: () => ({
    title: 'Listrik Desember 2021',
    total: 'Rp 90.000',
    status: 'paid',
    image: ''
  }),
  methods: {
    ...mapActions('bill', ['getBill', 'resetBill']),
    formatDate,
    formatNumberRupiah
  },
  computed: {
    ...mapState({
      bill: (state) => state.bill.bill
    })
  },
  mounted() {
    this.getBill(this.$route.params.id)
  },
  beforeDestroy() {
    this.resetBill()
  }
}
</script>