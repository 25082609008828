<template>
  <div v-if="mutation">
    <v-card :color="mutation.type == 'plus' ? 'light-blue darken-4' : 'light-blue darken-1'" dark class="rounded-0">
      <div class="d-flex flex-no-wrap justify-space-between">
        <div>
          <v-card-title class="text-h5" v-text="formatNumberRupiah(mutation.total)"></v-card-title>

          <v-card-subtitle v-text="mutation.name"></v-card-subtitle>
          <v-card-text
            ><i>{{ mutation.description }}</i></v-card-text
          >

          <v-card-actions>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on">
                  <v-icon left>mdi-pencil</v-icon>
                  {{ formatDate(mutation.created_at) }}
                </v-btn>
              </template>
              <span>Dibuat</span>
            </v-tooltip>
            <v-tooltip top color="primary" v-if="mutation.user">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on">
                  <v-icon left>mdi-account</v-icon>
                  {{ mutation.user.name }}
                </v-btn>
              </template>
              <span>User</span>
            </v-tooltip>
          </v-card-actions>
        </div>

        <v-avatar class="ma-5"  color="white" rounded>
        <v-icon x-large v-if="isPending" color="orange">mdi-cash-sync</v-icon>
        <v-icon x-large v-else-if="isCanceled" color="grey">mdi-cash-remove</v-icon>
        <v-icon x-large v-else-if="isPlus" color="green">mdi-cash-plus</v-icon>
        <v-icon x-large v-else color="red">mdi-cash-minus</v-icon>
        </v-avatar>
      </div>
    </v-card>
    <image-galery :images="mutation.images" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ImageGalery from '../components/ImageGalery.vue'
import { formatNumberRupiah, formatDate } from '../helpers/helper'
export default {
  components: {
    ImageGalery
  },
  computed: {
    ...mapState({
      mutation: (state) => state.mutation.mutation
    }),
    isCanceled() {
      return this.mutation.status == 'cancel'
    },
    isPending() {
      return this.mutation.status == 'pending'
    },
    isPlus() {
      return ['refund', 'topup', 'plus'].includes(this.mutation.type)
    },
  },
  methods: {
    ...mapActions('mutation', ['getMutation', 'resetMutation']),
    formatNumberRupiah,
    formatDate
  },
  mounted() {
    this.getMutation(this.$route.params.id)
  },
  beforeDestroy() {
    this.resetMutation()
  }
}
</script>