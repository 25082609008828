<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <template v-slot:activator="{ on, attrs }">
        <!-- <Tooltip icon="mdi-delete" text="Delete" title="Delete Invoice" color="danger" v-bind="attrs" v-on="on" /> -->
        <v-btn text v-bind="attrs" v-on="on" color="danger">
          <v-icon left>mdi-delete</v-icon>
          Delete
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5"> Are you sure want delete invoice {{ name }}? </v-card-title>
        <v-card-text> Tindakan tidak tidak dapat dibatalkan </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false"> Disagree </v-btn>
          <v-btn color="red darken-1" text @click="agreeDelete"> Agree </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  props: {
    id: String,
    name: String
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    ...mapActions('invoice', ['deleteInvoice']),
    agreeDelete() {
      this.deleteInvoice(this.id)
      this.dialog = false
    }
  }
}
</script>